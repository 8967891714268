<template>
  <x-dialog :proxy="applyDialog">
    <el-form label-width="100px">
      <el-form-item label="短信签名">
        <el-input
          v-model="inputData.sign_name"
          placeholder="请输入短信签名"
          style="width: 85%"
          required
        />
      </el-form-item>

      <el-form-item label="申请备注">
        <el-input
          v-model="inputData.reamrk"
          placeholder="例如：当前的短信签名应用于双11大促推广营销"
          style="width: 85%"
          required
        />
      </el-form-item>

      <el-form-item label="证明文件">
        <el-upload
          class="tip1upload"
          v-model="inputData.empower_img"
          :action="`${MixinUploadApi}?scene=shop`"
          list-type="picture-card"
          :on-remove="handleRemove"
          :file-list="fileList1"
          :on-progress="
            () => {
              btnClick = true;
            }
          "
          :on-success="uploadok"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt/>
        </el-dialog>
      </el-form-item>

      <el-form-item label="营业执照" error="true">
        <el-upload
          class="upload-demo tip1upload"
          :action="`${MixinUploadApi}?scene=shop`"
          v-model="inputData.licence_img"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          multiple
          :on-exceed="handleExceed"
          :file-list="fileList2"
          :on-success="uploadok1"
          :on-progress="
            () => {
              btnClick = true;
            }
          "
        >
          <el-button size="small" type="primary">申请企业的营业执照</el-button>
          <span slot="tip" class="el-upload__tip" style="margin-left: 5px"
          >只能上传jpg/png文件，且不超过500kb</span
          >
        </el-upload>

        <div slot="error" style="line-height: 1.5">
          <div>1、请上传申请企业的营业执照;</div>
          <div>2、支持JPG、PNG、GIF、JPEG格式的图片，每张图片不能大于1MB</div>
        </div>
      </el-form-item>
    </el-form>
  </x-dialog>
</template>

<script>
import * as API_account from '@/api/account';
import XDialog from '@/components/x-dialog/x-dialog';
import {$xDialog} from '@/components/x-dialog/dialog.proxy';

export default {
  components: {XDialog},
  props: {
    inputData: {
      type: Object,
      default: {
        sign_name: '',
        licence_img: '',
        empower_img: '',
        reamrk: '',
      },
    },
    fileList1: {
      type: Array,
      default: []
    },
    fileList2: {
      type: Array,
      default: []
    },
    // btnClick:{
    //   type: Boolean,
    //   default:false
    // }
  },
  data() {
    return {
      applyDialog: $xDialog.create({
        title: '申请短信签名',
        beforeConfirm: () => this.czShow(),
      }),
      input: '',
      dialogImageUrl: '',
      dialogVisible: false,
      // fileList2: [],
      // inputData: {
      //   sign_name: "",
      //   licence_img: "",
      //   empower_img: "",
      //   reamrk: "",
      // },
      btnClick: false
    };
  },
  mounted() {
    // this.fileList2 = [this.inputData.licence_img];
  },
  methods: {
    show() {
      this.applyDialog.display();
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除${file.name ? ' ' + file.name : '该文件'}？`);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // handlePreview(file) {
    //     console.log(file,"file");
    //   },
    handleRemove(file, fileList) {
      this.inputData.licence_img = '';
      if (fileList.length === 0) this.btnClick = true;
    },

    uploadok(response, file, fileList) {
      this.inputData.empower_img = response.url;
      this.btnClick = false;
    },
    uploadok1(response, file, fileList) {
      this.inputData.licence_img = response.url;
      this.btnClick = false;
    },
    // // putManage
    czShow() {
      if (!this.inputData.sign_name) {
        this.$message.error('请输入短信签名');
        return false;
      }

      if (!this.inputData.reamrk) {
        this.$message.error('请输入申请备注');
        return false;
      }

      if (!this.inputData.empower_img) {
        this.$message.error('请上传证明文件');
        return false;
      }

      if (!this.inputData.licence_img) {
        this.$message.error('请上传申请企业的营业执照');
        return false;
      }

      return new Promise(resolve => {
        let params = this.inputData;
        if (params.sign_id) {
          return API_account.putManage(params.sign_id, params).then((res) => {
            this.$message.success('申请修改成功');
            this.inputData.sign_name = '';
            this.inputData.licence_img = '';
            this.inputData.reamrk = '';
            this.$emit('refresh');
            this.applyDialog.dismiss();
            resolve(true);
          }, () => {
            resolve(false);
          });
        } else {
          return API_account.addManage(params).then((res) => {
            this.$message.success('申请成功');
            this.inputData.sign_name = '';
            this.inputData.licence_img = '';
            this.inputData.reamrk = '';
            this.applyDialog.dismiss();
            this.$emit('refresh');
            resolve(true);
          }, () => {
            resolve(false);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.contant:before {
  content: "*";
  color: #a91a1a;
  margin-right: 4px;
}

.btnn {
  padding-top: 50px;
  text-align: center;
}

.tip1 {
  margin-left: 100px;
  margin-bottom: 20px;
}

.tip1upload {
  display: inline-block;
}

.tip1 .el-upload--picture-card {
  border: 1px solid #c0ccda;
}

.tipBox {
  background: #fff;
  /* padding: 20px; */
  display: inline-block;
  width: 100%;
  /* margin-top: 20px; */
}

.tipBox p {
  font-size: 18px;
  color: #666666;
}

.tip1 {
  color: #666666;
}
</style>
